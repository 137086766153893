.imagelogo{
  width: 130px;
  margin: 0 auto;
  margin-bottom: 10px;
}


.wrappergrid{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrappergridcontent{
  max-width: 450px;
  width: 450px;
}

.labelEmail{
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #EF7C00;
}

.infoEmail{
font-size: 10px;
font-weight: 400;
line-height: 12.1px;
text-align: left;
color: #BEB8B5;
margin-top: -10px;
margin-bottom: 10px;
}
  

