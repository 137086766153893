body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-theme-balham .ag-row-group.ag-row.ag-row-level-0 {
  background-color: #eeeeee;
  font-weight: bold;
  border: 1px rgb(218, 218, 218) solid;
}



.clearmp{
  margin: 0px !important;
  padding: 0px !important;
}



.errorS {
  margin: auto;
  max-height: 500px;
  max-width: 700px;
  position: fixed;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgb(39, 39, 39);
}

.noInvoices{
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: center;
  color: #182542CC;

}

.statusred{
  background-color: #F9D5D5 !important;
  color: #A12121 !important;
}

.statusgreen{
  background-color: #C9E3C9 !important;
  color: #2E8132 !important;
}