body,
html {
  font-family: "Inter", sans-serif !important;
  background: #F2F2F2 !important;
  height: 100%;
  width: 100%;
}

.ui.orange.button {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.ui.button {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  color: #3C4456 !important;
}

.ui.segment {
  border-radius: 8px !important;
  border-color: #E6E6E6 !important;
}

.navBarElte {
 width: 100%;
 padding: 20px;
  background: #3C4456;
}

.navBarElteSmall {
  width: 100%;
  padding: 10px;
   background: #3C4456;
 }

table th {
  background-color: #EFF0F1 !important;
  color: #182542 !important;
}
table {
  border-color: #C7CED9 !important;;
}

.ui.label{
  width: 100%;
  text-align: center;
  
}
.appname{
  letter-spacing: 10px;
  color: #58595B;
  
}

.ui.table .headerfvtab  {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

